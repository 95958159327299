import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import PageBanner from "../components/Common/PageBanner";
import Footer from "../components/App/Footer";
import StartProject from "../components/Common/StartProject";

const FAQ = () => {
  return (
    <Layout>
      <Navbar />
      <PageBanner pageTitle="Često postavljana pitanja" />
      <div className="ptb-100">
        {/* <div className="section-title">
                    <h2>Frequently Asked Questions</h2>
            </div> */}
        <div className="container">
          <div className="faq-accordion">
            <Accordion>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    Da li mi je potrebna kreditna kartica da bih koristio
                    Tafoma?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    <strong>Ne</strong>, možete se pridružiti Tafomi besplatno,
                    sve što vam treba je e-mail adresa. Besplatni paket koji
                    nudi Tafoma nema vremenskih ograničenja. Ako ovaj paket
                    zadovoljava potrebe vašeg tima, možete ga koristiti zauvek,
                    bez ikakvih problema.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    Koja je kriva učenja aplikacije Tafoma?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Veoma laka. Arhitektonske komponente Tafome labavo spajaju
                    osnovno skladište i korisnički interfejs. Drugim rečima,
                    razvili smo Tafoma tako da ima intuitivan i jednostavan
                    UI/UKS dizajn koji čini platformu gotovo razumljivom.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    Kako pristupiti aplikaciji?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Možete lako da pristupite Tafomi sa bilo kog uređaja, ako
                    želite da koristite Tafoma preko pretraživača, samo idite na{" "}
                    <strong>
                      <a href="https://app.tafoma.com">
                        <u>https://app.tafoma.com</u>
                      </a>
                    </strong>
                    . Tafoma je takođe dostupna na mobilnom uređaju, sve što
                    treba da uradite je da kliknete na linkove ispod da biste
                    preuzeli aplikaciju.
                  </p>
                  <p>
                    <a href="https://apps.apple.com/us/app/tafoma/id1137130236">
                      App Store
                    </a>
                  </p>
                  <p>
                    <a href="https://play.google.com/store/apps/details?id=tafoma.com">
                      Play Store{" "}
                    </a>
                  </p>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    Kako napraviti profil?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <ul>
                    <li>
                      Da biste započeli, idite na{" "}
                      <strong>
                        <a href="https://app.tafoma.com"> Tafominu stranicu </a>
                      </strong>
                      za registraciju. Kliknite na dugme Registruj se.
                    </li>
                    <li>Sada možete da unesete svoje lične podatke.</li>
                    <li>Već ste tamo!</li>
                  </ul>
                  <p>
                    <sub>
                      *Naziv radnog prostora koji ste postavili je za vaš lični
                      radni prostor.
                    </sub>
                  </p>
                  <p>
                    <img
                      src="https://tafoma.com/wp-content/uploads/2020/12/ezgif.com-video-to-gif.gif"
                      alt=""
                      width="403"
                      height="250"
                    />
                  </p>
                </AccordionItemPanel>
              </AccordionItem>

              {/* <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Creating a Workspace
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>Creating a Workspace in Tafoma is easy, but it is important to understand how to mange more Workspaces, we recommend you check out this article on Workspaces.</p>
                                    <ul>
                                        <li>To create a Workspace go into Tafoma and click on the <strong>More</strong> tab.</li>
                                        <li>Than click on&nbsp;<strong>Workspaces</strong></li>
                                        <li>In the new window that opened, click <strong>New Workspace</strong></li>
                                        <li>Enter the name and click <strong>Create</strong>&nbsp;</li>
                                    </ul>
                                    <p><img src="https://tafoma.com/wp-content/uploads/2020/12/ScreenFlow.gif" alt="" width="487" height="300" /></p>
                                    <ul>
                                        <li>Tafoma transfers you into the new workspace and you can start inviting team members (invite Members).</li>
                                    </ul>
                                </AccordionItemPanel>
                            </AccordionItem> */}

              {/* <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Inviting Members
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>In order for Tafoma to help you with Team-Collaboration, you need to invite your team members to one Workspace.</p>
                                    <ul>
                                        <li>Inviting someone to Tafoma can be done from the <strong>+</strong> or from the Teams tab.</li>
                                        <li>All you need to do is <strong>enter their email address</strong> and select a team from which they are part of. (not mandatory)</li>
                                        <li>The new member that you invited will receive an email that will take them to the Sing-Up page and after they Sign-Up it will take them to the workspace. If the person is already registered in Tafoma alongside the email they will also receive a notification in Tafoma.</li>
                                        <li>Bellow that you can see the Permissions that you can allow this member to have, Read More about Permissions bellow.</li>
                                    </ul>
                                    <p><img src="https://tafoma.com/wp-content/uploads/2020/12/ScreenFlow2.gif" alt="" width="406" height="250" /></p>
                                    <h4>Permissions</h4>
                                    <p>When inviting a member or editing a member that has been invited by you, you can chose what things they can work on.</p>
                                    <p>Having the <strong>Project</strong> Permission off means that they can&rsquo;t make Projects in the Workspace. They will be able to see projects which are shared with them. <br />The same applies to <strong>Contacts</strong> and <strong>Tasks</strong>.</p>
                                    <p><br /><strong>Invite Members</strong>, this allows/disallows the new member to invite other members to the workspace. (We recommend turning this ON only for team-leaders)</p>
                                    <p><br /><strong>See Other Members</strong>, here you chose if the new member can see the Other Members in the workspace.</p>
                                    <p><br /><strong>External Followers</strong>, this allows/disallows the new member to invite external followers on task or projects.</p>
                                </AccordionItemPanel>
                            </AccordionItem> */}

              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    Koje su prednosti korišćenja neintegrativne platforme za
                    timsku komunikaciju i upravljanje projektima kao što je
                    Tafoma?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    U suštini, Tafoma se kao softver za upravljanje projektima i
                    timskom komunikacijom koristi u brojnim industrijama za
                    planiranje projekata, upravljanje vremenom, poboljšanje
                    komunikacije, alokaciju resursa i upravljanje promenama.
                  </p>
                  <p>
                    Međutim, Tafomina neintegrativna funkcija omogućava
                    ekonomičnost i besprekornu arhitekturu koja pomaže malim i
                    srednjim preduzećima da postanu što produktivnija i
                    konkurentnija i izdvaja ih od ostatka industrije.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    Koja je razlika između alatke Projekti i alatke Zadaci na
                    Tafomi?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Tafoma upravljanje projektima i upravljanje zadacima idu
                    ruku pod ruku.
                  </p>
                  <p>
                    Tafoma projekti su pristupačan i efikasan alat, gde možete
                    videti kako se projekat odvija - od koncepta do završetka,
                    oni sadrže prekretnice i jasan ishod. Daje vam pregled svih
                    zadataka.
                  </p>
                  <p>
                    Tafoma projekti su pristupačan i efikasan alat, gde možete
                    videti kako se projekat odvija - od koncepta do završetka,
                    oni sadrže prekretnice i jasan ishod. Daje vam pregled svih
                    zadataka.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    Kakva je mobilna verzija Tafome?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Prilikom dizajniranja našeg mobilnog interfejsa Tafoma,
                    obratili smo posebnu pažnju na niz principa za intuitivnu
                    interakciju: prikladnost za zadatak, kompatibilnost,
                    doslednost, geštalt zakoni (kako um grupiše slične elemente
                    zajedno, na osnovu njihovog oblika, boje, veličine i
                    osvetljenosti ), povratne informacije i samoopis. Naša
                    neintegrativna platforma takođe ne zahteva sinhronizaciju sa
                    softverom treće strane koji omogućava besprekoran rad
                    platforme.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    Koliko su bezbedni moji fajlovi i dokumenti i kakva je
                    Tafoma politika zaštite podataka?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    U skladu sa politikom privatnosti na mreži, ponosni smo što
                    Tafoma obezbeđuje dvostepeno šifrovanje tako da vaše
                    prikupljene, deljene i sačuvane informacije ostanu privatne
                    i bezbedne. Naš SSL sertifikat nudi potpunu zaštitu
                    podataka.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
      </div>
      <StartProject />
      <Footer />
    </Layout>
  );
};

export default FAQ;
